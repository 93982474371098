import towTruck from './../../assets/tow_truck.png'
import freeTruck from './../../assets/free-truck.png'
import notExpensive from './../../assets/not-expensive.png'
import allDay from './../../assets/24-7.png'
import fastTruck from './../../assets/fast.png'

const MainPage = () =>  {
  return (
    <section className="container">
      <div className="main-page">
        <div className="img">
          <img src={towTruck} alt="tow-truck"/>
        </div>

        <div className="title">
          <div className="title-text">Эвакуатор в Астане</div>
          <div className="description">
            Город<span></span>Межгород<span></span>Быстро<span></span>24/7
          </div>
          <p className="description-text">
            У нас можно заказать эвакуатор недорого по городу и межгород.
            Макс. вес до 5 тонн. Мы работаем круглосуточно.
          </p>
          <div className="title-btn">
            <button className="call-btn">
              <a href="tel:7778-533-3233">Вызвать эвакуатор</a>
            </button>
            <button className="call-btn">
              <a href="https://api.whatsapp.com/send?phone=77086690801&text=Здравствуйте,%20хочу%20заказать%20эвакуатор."
                 target="_blank">Написать эвакуатору</a>
            </button>
          </div>
        </div>
      </div>
      <div className="main-page-footer">
        <div className="main-page-footer-link">
          <div className="img">
            <img src={freeTruck} alt="1"/>
          </div>
          <h4>Бесплатная подача</h4>
        </div>
        <div className="main-page-footer-link">
          <div className="img">
            <img src={notExpensive} alt="1"/>
          </div>
          <h4>Недорого</h4>
        </div>
        <div className="main-page-footer-link">
          <div className="img">
            <img src={allDay} alt="1"/>
          </div>
          <h4>Круглосуточно</h4>
        </div>
        <div className="main-page-footer-link">
          <div className="img">
            <img src={fastTruck} alt="1"/>
          </div>
          <h4>Быстро от 15 минут</h4>
        </div>
      </div>
    </section>
  )
}

export default MainPage;