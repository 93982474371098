import arrow from './../../assets/arrow.png'

const WhyChooseUs = () => {
  return (
    <section className="container">
      <div className="why-choose-us">
        <div className="row">
          <div className="title">
            <h1>Почему выбирают наш</h1>
            <h1 className="title-sub">эвакуатор по городу и межгород</h1>
          </div>
          <div className="qualities">
            <div className="qualities-item">
              5+
              <span>лет опыта</span>
            </div>
            <div className="qualities-item">
              97%
              <span>довольных клиентов</span>
            </div>
            <div className="qualities-item">
              20
              <span>минут среднее ожидание</span>
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="row-2-block">
            <h1>
              Нужна
              <span>
                Помощь на дороге?
              </span>
            </h1>
            <div className="title-sub">Звони! Мы всегда рады помочь!</div>
          </div>
          <div className="row-2-block">
            <div className="call-me">
              <img src={arrow} alt="call-now"/>
              <a href="tel:7778-533-3233">+7 (778) 533-32-33</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs;