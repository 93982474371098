import logo from './../../assets/logo.png';
import call from './../../assets/phone.png';
import whatsapp from './../../assets/whatsapp.png';

const Header = () => {
  return (
    <header className="header">
      <div className="logo"><img src={logo} alt="logo"/></div>
      <nav className="nav-links">
        <ul>
          <li>
            <a href="https://api.whatsapp.com/send?phone=77086690801&text=Здравствуйте,%20хочу%20заказать%20эвакуатор."
              target="_blank">
              <img src={whatsapp} alt="whatsapp"/>
              +7 (708) 669<span>-</span>08<span>-</span>01
            </a>
          </li>
          <li>
            <a href="tel:7778-533-3233">
              <img src={call} alt="telephone"/>
              +7 (778) 533<span>-</span>32<span>-</span>33
            </a>
          </li>
        </ul>
      </nav>
      {/*<div className="tel">*/}
      {/*  <a*/}
      {/*    href="https://api.whatsapp.com/send?phone=77785333233&text=Здравствуйте,%20хочу%20заказать%20эвакуатор."*/}
      {/*    target="_blank">*/}
      {/*    +7 (778) 533<span>-</span>32<span>-</span>33*/}
      {/*  </a>*/}
      {/*</div>*/}
    </header>
  )
}

export default Header;