import React from 'react';
import './App.scss';
import Header from "./components/Header";
import MainPage from "./pages/MainPage";
import WhyChooseUs from "./pages/WhyChooseUs";
import HelpfulInfo from "./pages/HelpfulInfo";

function App() {
  return (
    <div className="App">
      <Header/>
      <MainPage/>
      <WhyChooseUs/>
      <HelpfulInfo/>
    </div>
  );
}

export default App;
