import time from './../../assets/time.png';
import price from './../../assets/price.png';
import lock from './../../assets/lock.png';
import info from './../../assets/info.png';
import plus from './../../assets/plus.png';
import minus from './../../assets/minus.png';
import {useEffect, useState} from "react";

const infoQuestions = [
  {
    id: 1,
    name: "Как долго ждать эвакуатор?",
    icon: 'time',
    answer: 'Среднее время подачи 20 минут. Оно зависит от загруженности автострады, а так же от наличия свободных эвакуаторов. Точное время мы сообщим после определения местоположения вас или вашего автомобиля.'
  },
  {
    id: 2,
    name: "От чего зависит цена эвакуатора?",
    icon: 'price',
    answer: 'Масса автотранспорта, который нуждается в эвакуации, существенно влияет на сумму заказа. Всех дешевле эвакуировать мотоцикл, мопед. Чем больше масса сломавшегося транспортного средства, тем дороже придётся заплатить за услугу.'
  },
  {
    id: 3,
    name: "Можно ли эвакуировать автомобиль с заблокированными колесами?",
    icon: 'lock',
    answer: 'Для транспортировки автомобиля без колес нужны специальные балки или стропы, которые помогут погрузить машину на эвакуатор: Автомобиль поднимается на требуемый уровень при помощи домкратов.'
  },
  {
    id: 4,
    name: "Какую информацию необходимо предоставить диспетчеру?",
    icon: 'info',
    answer: 'Когда мы получим от вас нужные сведения, то рассчитаем стоимость заказа и назовем сумму. Нужно будет подтвердить заказ — после этого эвакуатор сразу выезжает к месту погрузки (оказания помощи). Останется лишь дождаться прибытия эвакуаторщика. '
  }
];

const icons: any = {
  time: time,
  price: price,
  lock: lock,
  info: info,
}

const HelpfulInfo = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const setIndex = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  }

  return (
    <section className="container">
      <div className="section-title">Полезная <span>Информация</span></div>
      <div className="info-rows">
        {infoQuestions.map((item, index) => {
          return (
            <div className="row" key={item.id} onClick={() => setIndex(index)}>
              <div className="row-logo">
                <img src={icons[item.icon]} alt="row-logo"/>
              </div>
              <div className="row-question">
                <div className="question">
                  {item.name}
                  <p className={'answer' + (index === activeIndex ? ' active' : '') }>{item.answer}</p>
                </div>
                <div className="wrap-img">
                  <img src={index === activeIndex ? minus : plus} alt="wrap-img"/>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default HelpfulInfo